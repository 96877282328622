import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import { any } from 'prop-types'

// components
import Card from '@components/Card'
import Layout from '@compositions/Layout'
import Icon from './components/Icon'

// modules
import Url from '@modules/url'
import Router from '@modules/router'

// styles
import styles from './_.module.scss'

const NotFoundPage = ({ location }) => {
  const { search } = location || { search: '' }
  const { ref } = Url.parseSearch(search) || {}
  useEffect(() => {
    if (ref === 'success_login') {
      Router.go('/leads')
      return null
    }
  }, [ref])
  return (
    <Layout>
      <section className={cx('pa-3', 'bora', 'mat-4', styles.wrapper)}>
        <Card className={cx('pa-3', 'di-f', 'baco-white', styles.inner)}>
          <Icon className={styles.image} />

          <div className={styles.content}>
            <h1 className="mab-2 co-secondary-4">Page not found :(</h1>
            <p className="mab-2 co-gray-3">
              The page you are looking for does not exist.
            </p>

            <div className={cx('pat-3', 'mat-2', 'di-b', styles.actions)}>
              <Link to="/" title="Visit Partner Central homepage" tabIndex={0}>
                Go to homepage
              </Link>
            </div>
          </div>
        </Card>
      </section>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  location: any
}

NotFoundPage.defaultProps = {
  location: {}
}

export default NotFoundPage
